import { useQuery } from '@tanstack/react-query'

import { getExchangeRates } from '@/lib/api/wallets'
import { ExchangeRate } from '@/lib/types'

const useExchangeRates = () => {
  const { data, isLoading } = useQuery<{
    [key: string]: ExchangeRate
  }>({
    queryKey: ['rates'],
    queryFn: () => getExchangeRates(),
    staleTime: 30_000, // 30 seconds
    refetchInterval: 30_000, // 30 seconds
    retryDelay: 1_000 // 1 second
  })

  return { rates: data, isLoading }
}

export default useExchangeRates
