import { useRouter } from 'next/router'

import useRenderSide from '@/hooks/auth/useRenderSide'
import { useSignInStore } from '@/hooks/stores/useSignInStore'

export const useLandingRedirect = () => {
  const router = useRouter()
  const { isSignedIn } = useRenderSide()
  const { openSignInModal } = useSignInStore()

  const isLandingB = router.route.startsWith('/pages')

  const handleButtonClick = async () => {
    if (isLandingB) {
      await router.push('/')
    } else if (isSignedIn) {
      await router.push('/shop')
    } else {
      openSignInModal()
    }
  }

  return { handleButtonClick }
}
