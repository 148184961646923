import {
  CryptoCurrency,
  EstimateSwap,
  EstimateWithdrawal,
  ExchangeRate,
  ExchangeRateDTO,
  WalletAction,
  WalletActionDTO,
  WithdrawalMethod,
  estimateWithdrawalFromDTO,
  exchangeRateFromDTO,
  walletActionFromDTO
} from '@/lib/types'

import { fetchAPI } from '.'

export async function withdraw({
  amount,
  currency,
  method,
  csrfToken,
  account
}: {
  amount: number
  currency: string
  method: WithdrawalMethod
  account: string
  csrfToken: string
}): Promise<void> {
  await fetchAPI(
    'POST',
    'wallets/withdraw',
    {},
    {
      amount,
      currency,
      account,
      csrf_token: csrfToken,
      withdrawal_method: method
    }
  )
}

export async function estimateWithdrawal({
  amount,
  method,
  currency
}: {
  amount: number
  method: WithdrawalMethod
  currency: CryptoCurrency
}): Promise<EstimateWithdrawal> {
  const data = await fetchAPI(
    'POST',
    'wallets/estimate_withdrawal',
    {},
    {
      amount,
      withdrawal_method: method,
      currency
    }
  )

  return estimateWithdrawalFromDTO(data)
}

export async function swap({
  amount,
  from,
  to,
  csrfToken
}: {
  amount: number
  from: string
  to: string
  csrfToken: string
}): Promise<void> {
  await fetchAPI(
    'POST',
    'wallets/swap',
    {},
    {
      amount,
      from,
      to,
      csrf_token: csrfToken
    }
  )
}

export async function estimateSwap({
  amount,
  from,
  to
}: {
  amount: number
  from: string
  to: string
}): Promise<EstimateSwap> {
  const { currency, amount: estimateAmount } = await fetchAPI(
    'POST',
    'wallets/estimate_swap',
    {},
    {
      amount,
      from,
      to
    }
  )

  return { currency, amount: estimateAmount }
}

export async function getExchangeRates(): Promise<{
  [key: string]: ExchangeRate
}> {
  const data: { string: ExchangeRateDTO } = await fetchAPI(
    'GET',
    'wallets/rates'
  )

  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      exchangeRateFromDTO(value)
    ])
  )
}

export async function getWalletHistory({
  page
}: {
  page: number
}): Promise<{ actions: WalletAction[]; total: number }> {
  const { actions, total } = await fetchAPI('GET', 'wallets/history', { page })

  return {
    total,
    actions: actions.map((WalletAction: WalletActionDTO) =>
      walletActionFromDTO(WalletAction)
    )
  }
}
