import React, { CSSProperties, FC } from 'react'

import clsx from 'clsx'
import {
  VisuallyHidden,
  useNumberFormatter,
  useSlider,
  useSliderThumb
} from 'react-aria'

import { useSliderState } from '@react-stately/slider'
import { AriaNumberFieldProps } from '@react-types/numberfield'
import { AriaSliderProps } from '@react-types/slider'

interface SliderProps
  extends AriaSliderProps<number | number[]>,
    Pick<AriaNumberFieldProps, 'formatOptions'> {
  initialValue: number
  minValue: number
  maxValue: number
  step: number
  className?: string
  outputValue?: any
  label?: string
  trackClassName?: string
  thumbClassName?: string
}

const Slider: FC<SliderProps> = ({
  formatOptions,
  className,
  outputValue,
  label,
  trackClassName,
  thumbClassName,
  ...props
}) => {
  const trackRef = React.useRef(null)
  const numberFormatter = useNumberFormatter(formatOptions)
  let state = useSliderState({ ...props, numberFormatter })
  const { getThumbPercent } = state

  const { groupProps, trackProps, labelProps, outputProps } = useSlider(
    props,
    state,
    trackRef
  )

  return (
    <div className={clsx('select-none', className)} {...groupProps}>
      {(label || outputValue) && (
        <div className="flex items-start justify-between pt-2 text-center leading-4">
          <label {...labelProps}>{label}</label>
          <output {...outputProps}>{outputValue}</output>
        </div>
      )}
      <div className="flex py-4">
        <div
          {...trackProps}
          className={clsx(
            'h-1 w-full cursor-grab rounded-sm bg-slate-200',
            trackClassName
          )}
          ref={trackRef}
        >
          {state.values.map((_, i) => (
            <Thumb
              className={thumbClassName}
              index={i}
              key={i}
              state={state}
              trackRef={trackRef}
            />
          ))}
          {state.values.length === 2 && (
            <div
              className="absolute left-[var(--left)] top-0 h-1 w-[var(--width)] bg-blue-600"
              style={
                {
                  '--left': `${getThumbPercent(0) * 100}%`,
                  '--width': `${
                    (getThumbPercent(1) - getThumbPercent(0)) * 100
                  }%`
                } as CSSProperties
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

function Thumb({ ...props }) {
  const { state, trackRef, index, className } = props || {}
  const inputRef = React.useRef(null)

  const { thumbProps, inputProps, isDragging } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef
    },
    state
  )

  return (
    <div
      {...thumbProps}
      className={clsx(
        'top-1/2 rounded-full',
        isDragging ? 'h-3.5 w-3.5 bg-blue-500' : 'h-3 w-3 bg-blue-600',
        className
      )}
    >
      <VisuallyHidden>
        <input ref={inputRef} {...inputProps} />
      </VisuallyHidden>
    </div>
  )
}

export default Slider
