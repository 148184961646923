import Image from 'next/image'

import { FormattedMessage } from 'react-intl'

import { useLandingRedirect } from '@/hooks/landing/useLandingRedirect'

import Button from '@/components/Button'

const LandingIncreaseIncome = ({
  openPromoCodeDialog
}: {
  openPromoCodeDialog?: () => void
}) => {
  const { handleButtonClick } = useLandingRedirect()

  return (
    <div className="relative flex flex-col overflow-hidden rounded-x-3xl border border-slate-300 bg-primary-600 tablet:flex-row tablet:items-center">
      <div className="relative p-6 text-center tablet:z-[2] tablet:w-fit tablet:max-w-[500px] tablet:flex-none tablet:p-8 tablet:text-left desktop:max-w-[560px]">
        <h3 className="mb-5 text-2xl font-medium leading-7 text-white desktop:mb-8 desktop:text-3xl desktop:leading-3xl">
          <FormattedMessage
            id="landing.increase.income.text"
            defaultMessage="Инвестиции в облачный майнинг — понятный и прозрачный способ
            увеличить свой доход"
          />
        </h3>
        <Button
          className="mx-auto min-h-9 max-w-max bg-white !px-7 !py-1.5 hover:!bg-white tablet:ml-0 tablet:min-h-11"
          onClick={openPromoCodeDialog || handleButtonClick}
          variant="secondary"
        >
          <FormattedMessage
            {...{
              id: 'button.startEarn',
              defaultMessage: 'Начать зарабатывать'
            }}
          />
        </Button>
      </div>
      <div className="w-full tablet:absolute tablet:bottom-0 tablet:right-0 tablet:top-0 tablet:z-[1] tablet:w-[50%]">
        <Image
          className="block w-full tablet:hidden"
          alt="Money background"
          height={138}
          priority
          src="/images/money-bg-sm.svg"
          width={241}
        />
        <Image
          className="hidden w-full tablet:block desktop:hidden"
          alt="Money background"
          height={138}
          priority
          src="/images/money-bg-md.svg"
          width={241}
        />
        <Image
          className="hidden w-full desktop:block"
          alt="Money background"
          height={138}
          priority
          src="/images/money-bg-lg.svg"
          width={241}
        />
      </div>
    </div>
  )
}

export default LandingIncreaseIncome
