import { useRouter } from 'next/router'
import React from 'react'

import { FormattedMessage } from 'react-intl'

import { formatCurrency } from '@/lib/utils'

import Slider from '@/components/Slider'
import { LandingRadioYears } from '@/components/features/landing/LandingRadioYears'

const MAX_INVEST_AMOUNT = 250000 * 100
const RUB_STEP = 500

interface LandingCalculatorProps {
  rate: number
  investmentAmount: number
  years: number
  currency: string
  setInvestmentAmount: (value: number | number[]) => void
  setYears: (value: number) => void
}

export const LandingCalculator = ({
  rate,
  investmentAmount,
  currency,
  setInvestmentAmount,
  years,
  setYears
}: LandingCalculatorProps) => {
  const { locale } = useRouter()

  return (
    <div>
      <span className="text-x-base font-medium">
        <FormattedMessage
          {...{
            id: 'landing.durationInvestment',
            defaultMessage: 'Длительность вложений'
          }}
        />
      </span>
      <LandingRadioYears {...{ years, setYears }} />
      <div className="flex justify-between pb-3">
        <span className="text-x-base font-medium">
          <FormattedMessage
            {...{
              id: 'landing.investmentAmount',
              defaultMessage: 'Сумма инвестиций'
            }}
          />
        </span>
        <span className="text-x-base font-medium">
          {formatCurrency(investmentAmount, currency, locale)}
        </span>
      </div>
      <Slider
        aria-label="Investment amount"
        defaultValue={investmentAmount}
        initialValue={investmentAmount}
        maxValue={MAX_INVEST_AMOUNT}
        minValue={0}
        onChange={setInvestmentAmount}
        step={Math.ceil(RUB_STEP * rate * 100)}
        thumbClassName="!w-6 !h-6"
        trackClassName="h-1.5 rounded-md ml-2"
      />
    </div>
  )
}
