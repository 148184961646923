import React, { Dispatch } from 'react'

import clsx from 'clsx'
import { FormattedMessage, useIntl } from 'react-intl'

import { CURRENCIES } from '@/lib/constants'
import { Currency } from '@/lib/types'

import Icon from '@/components/Icon'
import { Dialog, SpecificDialogProps } from '@/components/dialogs/Dialog'

interface CurrencyDialogProps extends SpecificDialogProps {
  currency: Currency
  setCurrency: Dispatch<Currency>
}

interface CurrencyItemProps {
  currencyItem: Currency
  onClose: () => void
  currency: Currency
  setCurrency: Dispatch<Currency>
}

const CurrencyDialog = ({
  isOpen,
  currency,
  setCurrency,
  onClose
}: CurrencyDialogProps) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog
      {...{
        isOpen,
        onClose,
        className: 'tablet:!max-w-[660px] w-fit',
        title: formatMessage({ id: 'currency', defaultMessage: 'Валюта' })
      }}
    >
      <div className="flex flex-col">
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-1 phone-lg:grid-cols-2 container:grid-cols-3">
            {CURRENCIES.map((currencyItem, index) => (
              <CurrencyItem
                key={index}
                {...{
                  currencyItem,
                  currency,
                  setCurrency,
                  onClose
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const CurrencyItem = ({
  currencyItem,
  currency,
  onClose,
  setCurrency
}: CurrencyItemProps) => {
  const { code, title, symbol } = currencyItem

  const active = currency.code === code

  return (
    <div
      className={clsx(
        'flex cursor-pointer select-none items-center justify-between rounded-lg py-[10px] pl-3 pr-[10px] text-x-base',
        active && 'bg-slate-100 font-medium'
      )}
      onClick={() => {
        setCurrency(currencyItem)
        onClose()
      }}
    >
      <div className="flex flex-col">
        <span className="tablet:text-x-md">
          <FormattedMessage
            {...{
              id: `currency.${code.toLowerCase()}`,
              defaultMessage: title
            }}
          />
        </span>
        <span className="font-normal text-slate-500">
          {code} — {symbol}
        </span>
      </div>
      <div
        className={clsx(
          'relative h-6 w-6 flex-none rounded-full',
          active && 'border bg-primary-600'
        )}
      >
        <Icon
          className="absolute bottom-[5px] left-[5px] h-3 w-3 text-white"
          name="checkbox-20"
        />
      </div>
    </div>
  )
}

export default CurrencyDialog
