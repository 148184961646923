import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { CURRENCIES } from '@/lib/constants'
import { Currency } from '@/lib/types'
import { formatCurrency } from '@/lib/utils'

import { useDialog } from '@/hooks/dialogs/useDialog'
import { useLandingProfitCalculator } from '@/hooks/landing/useLandingProfitCalculator'
import { useLandingRedirect } from '@/hooks/landing/useLandingRedirect'

import Button from '@/components/Button'
import CurrencyDialog from '@/components/dialogs/landing/CurrencyDialog'
import { LandingCalculator } from '@/components/features/landing/LandingCalculator'
import LandingTable from '@/components/features/landing/LandingTable'

const LandingCalculateProfit = ({
  openPromoCodeDialog
}: {
  openPromoCodeDialog?: () => void
}) => {
  const { locale } = useRouter()
  const { handleButtonClick } = useLandingRedirect()

  const [currency, setCurrency] = useState<Currency>(CURRENCIES[0])

  const { isOpen, openModal, closeModal } = useDialog()

  const {
    rate,
    years,
    setYears,
    investmentAmount,
    setInvestmentAmount,
    netProfit,
    allTimeProfit
  } = useLandingProfitCalculator({ currency })

  return (
    <div
      className={clsx(
        'flex flex-col items-center gap-6 overflow-hidden rounded-x-3xl border',
        'border-slate-300 px-3 pb-6 pt-[22px] tablet:gap-10 tablet:p-5 tablet:pb-[34px] desktop:p-[50px]'
      )}
    >
      <div className="w-full">
        <div className="flex w-full flex-col items-center gap-5 tablet:flex-row tablet:items-end tablet:justify-between desktop:mb-10">
          <div className="mb-5 flex flex-none flex-col max-tablet:order-1">
            <h3
              className={clsx(
                'mb-6 text-center text-2xl font-medium leading-7 tablet:text-left tablet:text-3xl',
                'tablet:leading-x-base desktop:mb-8 desktop:text-4xl desktop:leading-[1.194]'
              )}
            >
              <FormattedMessage
                defaultMessage="Рассчитайте {br} свою прибыль в <span>{code}</span>"
                id="landing.calculate.title"
                values={{
                  br: <br />,
                  code: currency.code,
                  span: (chunks) => (
                    <span
                      className="cursor-pointer text-primary-600 hover:text-primary-500"
                      onClick={openModal}
                    >
                      {chunks}
                    </span>
                  )
                }}
              />
            </h3>
            <LandingCalculator
              {...{
                rate,
                investmentAmount,
                setInvestmentAmount,
                years,
                setYears,
                currency: currency.code
              }}
            />
            <CurrencyDialog
              {...{ isOpen, onClose: closeModal, currency, setCurrency }}
            />
          </div>
          <div className="relative z-[1] flex-none">
            <Image
              className="block h-[168px] w-auto tablet:hidden desktop:block desktop:h-[271px]"
              alt="Calculate dog"
              height={170}
              priority
              src="/images/dog-calculate.svg"
              width={212}
            />
            <Image
              className="hidden h-full w-auto tablet:block desktop:hidden"
              alt="Calculate dog"
              height={170}
              priority
              src="/images/dog-calculate-md.svg"
              width={212}
            />
          </div>
        </div>
        <div
          className={clsx(
            'relative z-[2] flex w-full flex-col items-center gap-4 overflow-hidden rounded-2xl border border-slate-300 bg-[#F1F4F7]',
            'p-3 max-desktop:-mt-1.5 tablet:flex-row tablet:justify-between tablet:px-5 tablet:py-[14px] desktop:px-[30px] desktop:py-[18px]'
          )}
        >
          <div className="flex items-center gap-4 tablet:gap-5">
            <div className="block h-[42px] flex-none tablet:h-[58px]">
              <Image
                className="block h-full w-auto"
                alt="Calculate dog"
                height={170}
                priority
                src="/images/bitcoin.svg"
                width={212}
              />
            </div>
            <div className="flex flex-col gap-1 tablet:gap-1.5">
              <p className="text-x-base font-medium leading-x-base">
                <FormattedMessage
                  defaultMessage="Чистая прибыль"
                  id="landing.profitabilityForecast.profit"
                />
              </p>
              <h3 className="leading-2xl text-2xl font-medium tablet:text-3xl tablet:leading-3xl desktop:text-4xl desktop:leading-10">
                ~ {formatCurrency(netProfit, currency.code, locale)}
              </h3>
            </div>
          </div>
          <Button
            className="min-h-9 !px-7 !py-1.5 tablet:min-h-11 tablet:!px-8"
            onClick={openPromoCodeDialog || handleButtonClick}
          >
            <FormattedMessage
              {...{
                id: 'button.startInvest',
                defaultMessage: 'Начать инвестировать в майнинг'
              }}
            />
          </Button>
        </div>
      </div>
      <div className="w-full">
        <h3 className="mb-4 text-xl font-medium leading-xl tablet:mb-6 tablet:text-2xl tablet:leading-x-base">
          <FormattedMessage
            defaultMessage="Прогноз доходности"
            id="landing.profitabilityForecast.title"
          />
        </h3>
        <LandingTable {...{ allTimeProfit, years, currency: currency.code }} />
      </div>
    </div>
  )
}

export default LandingCalculateProfit
