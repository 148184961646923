import React from 'react'

import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import { RadioGroup } from '@headlessui/react'

export const LandingRadioYears = ({
  years,
  setYears
}: {
  years: number
  setYears: (value: number) => void
}) => {
  const YEARS = [1, 2, 3, 4, 5, 6]

  return (
    <RadioGroup
      className="mb-4 mt-3 flex rounded-xl border-[0.5px] border-slate-300 tablet:mb-6 desktop:mb-8"
      onChange={(value) => setYears(value)}
      value={years}
    >
      <>
        {YEARS.map((year) => {
          return (
            <RadioGroup.Option
              className={clsx(
                'cursor-pointer select-none border-r-[0.5px] border-slate-300 text-x-base phone-lg:min-w-[55px]',
                'w-full whitespace-nowrap text-center font-medium text-secondary-500 last:border-r-0'
              )}
              key={year}
              value={year}
            >
              {({ checked }) => (
                <div
                  className={clsx(
                    'w-full !px-2 !py-1.5 last:border-r-0 tablet:!px-3 desktop:!px-4',
                    year === YEARS.at(0) && 'rounded-l-xl',
                    year === YEARS.at(-1) && 'rounded-r-xl',
                    checked && 'bg-primary-600 text-white'
                  )}
                >
                  <span className="hidden tablet:block">
                    <FormattedMessage
                      {...{ id: 'unit.year', values: { value: year } }}
                    />
                  </span>
                  <span className="block tablet:hidden">
                    <FormattedMessage
                      {...{ id: 'unit.year.short', values: { value: year } }}
                    />
                  </span>
                </div>
              )}
            </RadioGroup.Option>
          )
        })}
      </>
    </RadioGroup>
  )
}
