import { useEffect, useMemo, useState } from 'react'

import { Currency } from '@/lib/types'

import useExchangeRates from '@/hooks/finances/useExchangeRates'

export interface TotalProfit {
  current: number
  '75k': number
  '100k': number
}

const DEFAULT_RATE = 1
const DEFAULT_USD_RUB_RATE = 80

const DEFAULT_INVESTMENT_AMOUNT = 30000 * 100 // RUB
const DEFAULT_YEARS = 2

const COST_ELECTRICITY = 5.6 * 100 // RUB per kWh
const COST_PER_TH = 2500 * 100 // RUB
const POWER_CONSUMPTION_TH = 0.021 // kWh

const ELECTRICITY_COST_PER_TH_YEARLY =
  COST_ELECTRICITY * POWER_CONSUMPTION_TH * 24 * 365 // 864.612 RUB

const MINING_PROFIT_CURRENT_DAILY_BTC = 0.0000025 // BTC/TH per day

const MINING_PROFIT_100k_DAILY = 21.0 * 100 // BTC/TH
const MINING_PROFIT_100k_YEARLY = MINING_PROFIT_100k_DAILY * 365 // 5442.15 RUB

const MINING_PROFIT_75k_DAILY = 14.91 * 100 // BTC/TH
const MINING_PROFIT_75k_YEARLY = MINING_PROFIT_75k_DAILY * 365 // 3628.1 RUB

const MINING_INTEREST = 0.15 // 15%

export const useLandingProfitCalculator = ({
  currency
}: {
  currency: Currency
}) => {
  const { rates } = useExchangeRates()

  const [years, setYears] = useState<number>(DEFAULT_YEARS)
  const [investmentAmount, setInvestmentAmount] = useState<any>(
    DEFAULT_INVESTMENT_AMOUNT
  )
  const [netProfit, setNetProfit] = useState<number>(0)
  const [allTimeProfit, setAllTimeProfit] = useState<TotalProfit | null>(null)

  const rate = useMemo(() => {
    switch (currency.code) {
      case 'USD':
        return 1 / (rates?.['USD/RUB'].value || DEFAULT_USD_RUB_RATE)
      case 'BYN':
        return 1 / (rates?.['BYN/RUB']?.value || 1)
      case 'RUB':
        return DEFAULT_RATE
      default:
        return DEFAULT_RATE
    }
  }, [currency.code, rates])

  useEffect(() => {
    const purchasedTHs = Math.floor(investmentAmount / COST_PER_TH)

    const netProfit = getNetProfit(purchasedTHs, years, rate)
    setNetProfit(netProfit)

    const yearlyMiningProfit =
      MINING_PROFIT_CURRENT_DAILY_BTC *
      (rates?.['BTC/RUB'].value || 0) *
      365 *
      100

    const allTimeProfit = getAllTimeProfit({
      purchasedTHs,
      yearlyMiningProfit,
      rate
    })

    setAllTimeProfit(allTimeProfit)
  }, [years, investmentAmount, rates, rate])

  return {
    rate,
    years,
    setYears,
    investmentAmount: Math.ceil((investmentAmount * rate) / 100) * 100,
    setInvestmentAmount,
    netProfit,
    allTimeProfit
  }
}

const getNetProfit = (purchasedTHs: number, years: number, rate: number) => {
  const expenses = calcExpenses(purchasedTHs, years)
  const profit = purchasedTHs * MINING_PROFIT_100k_YEARLY * years

  const netProfit = calcProfitWithProtocolFee(profit - expenses, rate)

  return netProfit
}

const getAllTimeProfit = ({
  purchasedTHs,
  yearlyMiningProfit,
  rate
}: {
  purchasedTHs: number
  yearlyMiningProfit: number
  rate: number
}) => {
  const profitValues = [
    yearlyMiningProfit,
    MINING_PROFIT_75k_YEARLY,
    MINING_PROFIT_100k_YEARLY
  ]

  const allTimeExpenses = calcExpenses(purchasedTHs)

  const allTimeIncome = profitValues.map((profit) => purchasedTHs * profit)

  return {
    current: calcProfitWithProtocolFee(
      allTimeIncome[0] - allTimeExpenses,
      rate
    ),
    '75k': calcProfitWithProtocolFee(allTimeIncome[1] - allTimeExpenses, rate),
    '100k': calcProfitWithProtocolFee(allTimeIncome[2] - allTimeExpenses, rate)
  }
}

const calcProfitWithProtocolFee = (profit: number, rate: number) => {
  const netProfit = profit * (1 - MINING_INTEREST)

  return netProfit * rate
}

const calcExpenses = (purchasedTHs: number, years: number = 1) => {
  const expenses = purchasedTHs * ELECTRICITY_COST_PER_TH_YEARLY * years

  return expenses
}
