import { useRouter } from 'next/router'

import clsx from 'clsx'
import { range } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

import { formatCurrency } from '@/lib/utils'

import { TotalProfit } from '@/hooks/landing/useLandingProfitCalculator'

interface LandingTableProps {
  allTimeProfit: TotalProfit | null
  years: number
  currency: string
}

const MONTHS_IN_YEAR = 12

const LandingTable = ({
  allTimeProfit,
  years,
  currency
}: LandingTableProps) => {
  const { locale } = useRouter()

  const rowCount = years + 1

  const {
    current: currentProfit = 0,
    '75k': seventyFiveKProfit = 0,
    '100k': oneHundredKProfit = 0
  } = allTimeProfit || {}

  return (
    <div className="flex flex-auto flex-col gap-4 tablet:gap-[14px]">
      <div className="hidden flex-auto gap-3 rounded-xl py-[21px] text-x-base font-medium leading-x-base tablet:flex tablet:gap-6 tablet:bg-slate-100 tablet:px-5 tablet:text-x-md tablet:leading-x-md">
        <span className="w-1/4 pr-4 desktop:w-[22%]">
          <FormattedMessage
            {...{
              id: 'landing.stats.period',
              defaultMessage: 'Период вложения'
            }}
          />
        </span>
        <span className="w-1/4 px-5 text-center desktop:w-[26%] desktop:pr-0 desktop:text-right">
          <FormattedMessage
            {...{
              id: 'landing.stats.rate.current',
              defaultMessage: 'Текущий курс BTC'
            }}
          />
        </span>
        <span className="w-1/4 text-center desktop:w-[26%] desktop:text-right">
          {formatCurrency(1, 'BTC', locale)} ={' '}
          {formatCurrency(75000_00, 'USD', locale)}
        </span>
        <span className="w-1/4 text-center desktop:w-[26%] desktop:text-right">
          {formatCurrency(1, 'BTC', locale)} ={' '}
          {formatCurrency(100000_00, 'USD', locale)}
        </span>
      </div>

      {range(0, years + 1).map((item) => (
        <div
          className={clsx(
            'flex w-full flex-col gap-3 rounded-xl border-[0.5px] border-slate-300 p-4 text-x-base leading-x-base max-tablet:hidden',
            'tablet:flex-row tablet:gap-6 tablet:border-0 tablet:bg-slate-100 tablet:px-5 tablet:py-[18px] tablet:text-x-md tablet:leading-x-md'
          )}
          key={item}
        >
          <div className="flex gap-3 tablet:w-1/4 desktop:w-[22%]">
            <span className="w-[55%] tablet:w-full tablet:font-medium">
              <FormattedMessage
                {...{
                  id: item ? 'unit.year' : 'partner.statistics.table.month',
                  defaultMessage: item
                    ? '{value, plural, one {# год} few {# года} many {# лет}  other {# лет}}'
                    : 'Месяц',
                  values: { value: item }
                }}
              />
            </span>
          </div>
          <div className="flex gap-3 tablet:w-1/4 tablet:justify-center tablet:text-center desktop:w-[26%] desktop:justify-end desktop:text-right">
            ~{' '}
            {formatCurrency(
              item ? currentProfit * item : currentProfit / MONTHS_IN_YEAR,
              currency,
              locale
            )}
          </div>
          <div className="flex gap-3 tablet:w-1/4 tablet:justify-center tablet:text-center desktop:w-[26%] desktop:justify-end desktop:text-right">
            ~{' '}
            {formatCurrency(
              item
                ? seventyFiveKProfit * item
                : seventyFiveKProfit / MONTHS_IN_YEAR,
              currency,
              locale
            )}
          </div>
          <div className="hidden gap-3 tablet:block tablet:w-1/4 tablet:justify-end tablet:text-right desktop:w-[26%]">
            ~{' '}
            {formatCurrency(
              item
                ? oneHundredKProfit * item
                : oneHundredKProfit / MONTHS_IN_YEAR,
              currency,
              locale
            )}
          </div>
        </div>
      ))}

      <MobileTable {...{ rowCount, allTimeProfit, currency }} />
    </div>
  )
}

const MobileTable = ({
  currency,
  rowCount,
  allTimeProfit
}: {
  currency: string
  rowCount: number
  allTimeProfit: TotalProfit | null
}) => {
  const { locale } = useRouter()
  const { formatMessage } = useIntl()

  const {
    current: currentProfit = 0,
    '75k': seventyFiveKProfit = 0,
    '100k': oneHundredKProfit = 0
  } = allTimeProfit || {}

  const tableData = [
    {
      title: formatMessage({
        id: 'landing.stats.rate.current',
        defaultMessage: 'Текущий курс BTC'
      }),
      profit: currentProfit
    },
    {
      title: [
        formatCurrency(1, 'BTC', locale),
        formatCurrency(75000_00, 'USD', locale)
      ].join(' = '),
      profit: seventyFiveKProfit
    },
    {
      title: [
        formatCurrency(1, 'BTC', locale),
        formatCurrency(100000_00, 'USD', locale)
      ].join(' = '),
      profit: oneHundredKProfit
    }
  ]

  return tableData.map(({ title, profit }, index) => (
    <div
      className={clsx(
        'flex w-full flex-col gap-3 rounded-xl border-[0.5px]',
        'border-slate-300 p-4 text-x-base leading-x-base tablet:hidden'
      )}
      key={index}
    >
      <div className="-mx-4 -mt-4 border-b-[0.5px] border-slate-300 p-4 tablet:hidden">
        <span className="block font-medium">{title}</span>
      </div>
      {range(0, rowCount).map((item) => (
        <div className="flex gap-3 tablet:w-1/4" key={item}>
          <span className="w-[55%] tablet:hidden">
            <FormattedMessage
              {...{
                id: item ? 'unit.year' : 'partner.statistics.table.month',
                defaultMessage: item
                  ? '{value, plural, one {# год} few {# года} many {# лет}  other {# лет}}'
                  : 'Месяц',
                values: { value: item }
              }}
            />
          </span>
          <span>
            ~{' '}
            {formatCurrency(
              item ? profit * item : profit / MONTHS_IN_YEAR,
              currency,
              locale
            )}
          </span>
        </div>
      ))}
    </div>
  ))
}

export default LandingTable
