import { useRouter } from 'next/router'

import { formatCurrency } from '@/lib/utils'

const RATE = 100 // 1$ = 100 RUB

export const useLandingCurrencyConverter = ({
  amountInRUB
}: {
  amountInRUB: number
}) => {
  const { locale } = useRouter()

  const isRULocale = locale === 'ru'
  const amount = (isRULocale ? amountInRUB : amountInRUB / RATE) * 100
  const currency = isRULocale ? 'RUB' : 'USD'

  return { amount: formatCurrency(amount, currency, locale) }
}
